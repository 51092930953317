import React, {Component} from 'react';

class EventBanner extends Component{
    state = {
        open: true
    };
    
    onOpenModal = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };
    render(){
        return(
            <section className="event_banner_area" id="home">
                <div className="parallax-effect" style={{backgroundImage: "url(" + (require("../img/home-event/municipios.jpg")) + ")"}}></div>
                <div className="container">
                    <div className="event_banner_content">
                        <div className="round wow zoomIn" data-wow-delay="0.2s">
                        </div>
                        <h6 className="wow fadeInUp" data-wow-delay="0.6s">19 e 20 de Janeiro de 2023</h6>
                        <h2 className="wow fadeInUp" data-wow-delay="0.8s">Congresso <br></br><span> dos Municípios</span><br></br></h2>
                        <h6 className="wow fadeInUp mb-5" data-wow-delay="0.6s">Eficiência e Transparência na Gestão Pública</h6>
                        <a className="event_btn btn_hover wow fadeInLeft" data-wow-delay="0.9s" href="http://amacursos.com.br/" target={'_blank'}>Inscrições</a>
                        <a className="event_btn event_btn_banner event_btn_two btn_hover wow fadeInRight" data-wow-delay="0.9s" href="https://www.youtube.com/watch?v=S719j6wChh0"><i className="arrow_triangle-right_alt2"></i>Conheça</a>
                    </div>
                </div>
            </section>
        )
    }
}

export default EventBanner;